// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../k8-opt-exec-ST-13d3ddad9198/bin/redo/shopify-extension/webpack.bin.runfiles/npm_css-loader_6.7.3-dc3fc578/files/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../k8-opt-exec-ST-13d3ddad9198/bin/redo/shopify-extension/webpack.bin.runfiles/npm_css-loader_6.7.3-dc3fc578/files/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._z-0 {\n  z-index: 2147483005 !important; /* 2 higher than the intercom chat bubble */\n}\n\n._z-1 {\n  background-color: var(--redo-colors-background-bg-primary);\n  color: var(--redo-colors-text-text-primary-900);\n  box-shadow: 0px 14px 40px rgba(24, 24, 24, 0.09);\n  margin: 8px;\n  transition:\n    opacity 300ms,\n    height 300ms,\n    transform 300ms;\n  transform-origin: top center;\n  overflow-y: auto;\n}\n\n._z-1._z-2 {\n    max-height: 415px;\n  }\n\n._z-3 {\n  overflow-y: hidden;\n}\n\n._z-1._z-4 {\n  background: var(--redo-component-colors-utility-gray-utility-gray-700);\n  border-radius: var(--redo-corner-small-radius);\n}\n\n._z-1._z-5 {\n  opacity: 0;\n  transform: scale(0.92);\n}\n\n._z-1._z-6 {\n  background-color: var(--redo-colors-background-bg-primary);\n}\n\n._z-7 {\n  font-size: var(--redo-body-medium-text-size);\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 12px 16px;\n  cursor: pointer;\n  border-radius: 10px;\n  transition: background-color 200ms;\n}\n\n._z-7._z-4 {\n  width: 100%;\n}\n\n._z-7._z-4:hover {\n  background-color: var(--redo-nav-background-active);\n}\n\n.Mui-focusVisible ._z-7 {\n    background-color: var(--redo-colors-background-bg-primary_hover) !important;\n  }\n\n._z-7:hover {\n  background-color: var(--redo-colors-background-bg-primary_hover);\n}\n\n._z-7:disabled {\n  text-decoration: line-through;\n  color: var(--redo-colors-text-text-disabled);\n}\n", ""]);
// Exports
export var root = "_z-0";
export var dropdown = "_z-1";
export var constrainHeight = "_z-2";
export var animating = "_z-3";
export var dark = "_z-4";
export var leave = "_z-5";
export var background = "_z-6";
export var option = "_z-7";
export default ___CSS_LOADER_EXPORT___;
