// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../k8-opt-exec-ST-13d3ddad9198/bin/redo/shopify-extension/webpack.bin.runfiles/npm_css-loader_6.7.3-dc3fc578/files/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../k8-opt-exec-ST-13d3ddad9198/bin/redo/shopify-extension/webpack.bin.runfiles/npm_css-loader_6.7.3-dc3fc578/files/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._e-0 {\n  display: flex;\n}\n\n._e-1 {\n  flex-direction: row;\n}\n\n._e-1._e-2 {\n    flex-direction: row-reverse;\n  }\n._e-3 {\n  flex-direction: column;\n}\n._e-3._e-2 {\n    flex-direction: column-reverse;\n  }\n\n._e-4 {\n  align-items: center;\n}\n._e-5 {\n  align-items: flex-start;\n}\n._e-6 {\n  align-items: flex-end;\n}\n._e-7 {\n  align-items: stretch;\n}\n._e-8 {\n  align-items: baseline;\n}\n\n._e-9 {\n  align-self: center;\n}\n._e-a {\n  align-self: flex-start;\n}\n._e-b {\n  align-self: flex-end;\n}\n._e-c {\n  align-self: stretch;\n}\n._e-d {\n  align-self: baseline;\n}\n\n._e-e {\n  justify-content: center;\n}\n._e-f {\n  justify-content: flex-start;\n}\n._e-g {\n  justify-content: flex-end;\n}\n._e-h {\n  justify-content: space-between;\n}\n._e-i {\n  justify-content: space-around;\n}\n._e-j {\n  justify-content: space-evenly;\n}\n\n._e-k {\n  flex-wrap: wrap;\n}\n\n._e-l {\n  flex-wrap: nowrap;\n}\n\n._e-m {\n  flex-wrap: wrap-reverse;\n}\n", ""]);
// Exports
export var flex = "_e-0";
export var row = "_e-1";
export var reverse = "_e-2";
export var column = "_e-3";
export var alignCenter = "_e-4";
export var alignFlexStart = "_e-5";
export var alignFlexEnd = "_e-6";
export var alignStretch = "_e-7";
export var alignBaseline = "_e-8";
export var alignSelfCenter = "_e-9";
export var alignSelfFlexStart = "_e-a";
export var alignSelfFlexEnd = "_e-b";
export var alignSelfStretch = "_e-c";
export var alignSelfBaseline = "_e-d";
export var justifyCenter = "_e-e";
export var justifyFlexStart = "_e-f";
export var justifyFlexEnd = "_e-g";
export var justifySpaceBetween = "_e-h";
export var justifySpaceAround = "_e-i";
export var justifySpaceEvenly = "_e-j";
export var wrapWrap = "_e-k";
export var wrapNowrap = "_e-l";
export var wrapWrapReverse = "_e-m";
export default ___CSS_LOADER_EXPORT___;
