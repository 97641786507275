// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../k8-opt-exec-ST-13d3ddad9198/bin/redo/shopify-extension/webpack.bin.runfiles/npm_css-loader_6.7.3-dc3fc578/files/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../k8-opt-exec-ST-13d3ddad9198/bin/redo/shopify-extension/webpack.bin.runfiles/npm_css-loader_6.7.3-dc3fc578/files/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._f-0 {\n  height: 40px;\n  width: 40px;\n  min-height: 40px;\n  min-width: 40px;\n  object-fit: contain;\n  cursor: pointer;\n  border-radius: var(--border-radius-override, var(--redo-xsmall-radius));\n  border: var(--redo-border-width) solid\n    var(--redo-colors-border-border-secondary);\n}\n\n._f-1 {\n  display: flex;\n  align-items: center;\n}\n\n._f-1 svg {\n    border-radius: var(--border-radius-override, var(--redo-xsmall-radius));\n  }\n\n._f-2 {\n  text-overflow: ellipsis;\n  color: var(--redo-colors-text-text-secondary-700);\n}\n\n._f-3 {\n  background: #141414;\n  width: 40px;\n  display: flex;\n  justify-content: center;\n  border-radius: var(--border-radius-override, var(--redo-xsmall-radius));\n}\n\n._f-3 svg {\n    height: 40px;\n    color: var(--redo-colors-text-text-primary-900);\n  }\n\n._f-4 {\n  margin-left: auto;\n}\n\n._f-5 {\n  display: flex;\n  flex-direction: column;\n  color: var(--redo-colors-text-text-primary-900);\n  width: calc(70% - 16px); /* 16px is the padding of the file-container */\n}\n\n._f-6 {\n  display: flex;\n  flex-direction: column;\n  color: var(--redo-colors-text-text-primary-900);\n  width: 90%;\n}\n\n._f-7 {\n  display: flex;\n  flex-direction: row;\n  gap: 8px;\n  background: var(--redo-colors-background-bg-disabled);\n  border: 1px solid var(--redo-colors-border-border-primary);\n  border-radius: var(--border-radius-override, var(--redo-xsmall-radius));\n  align-items: center;\n  height: 65px;\n  padding: 8px;\n  cursor: pointer;\n  min-width: 0;\n}\n\n._f-7:hover {\n    border: 1px solid var(--redo-colors-border-border-primary-hover);\n  }\n\n._f-8 {\n  text-overflow: ellipsis;\n  overflow: hidden;\n}\n\n._f-9 {\n  max-width: 100%;\n}\n", ""]);
// Exports
export var image = "_f-0";
export var fileType = "_f-1";
export var fileTypeName = "_f-2";
export var defaultWrapper = "_f-3";
export var downloadButton = "_f-4";
export var fileInfo = "_f-5";
export var fileInfoWidthRestricted = "_f-6";
export var fileContainer = "_f-7";
export var overflowEllipsis = "_f-8";
export var link = "_f-9";
export default ___CSS_LOADER_EXPORT___;
