// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../k8-opt-exec-ST-13d3ddad9198/bin/redo/shopify-extension/webpack.bin.runfiles/npm_css-loader_6.7.3-dc3fc578/files/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../k8-opt-exec-ST-13d3ddad9198/bin/redo/shopify-extension/webpack.bin.runfiles/npm_css-loader_6.7.3-dc3fc578/files/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/** All buttons are a flex container with a center alignment */\n._11-0 {\n  display: flex;\n  cursor: pointer;\n  position: relative;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  overflow: hidden;\n  transition:\n    background-color var(--redo-state-duration),\n    color var(--redo-state-duration);\n\n  border-radius: 8px;\n}\n._11-0._11-1,\n  ._11-0._11-2 {\n    border-radius: 6px;\n  }\n._11-0._11-1 {\n    height: 24px;\n  }\n._11-0._11-2 {\n    height: 28px;\n  }\n._11-0._11-3 {\n    height: 36px;\n  }\n._11-0._11-4 {\n    height: 40px;\n  }\n._11-0._11-5 {\n    height: 44px;\n  }\n\n._11-6._11-1 > * {\n      height: 12px;\n      width: 12px;\n    }\n\n._11-6._11-2 > * {\n      height: 14px;\n      width: 14px;\n    }\n\n._11-6._11-3 > *, ._11-6._11-4 > *, ._11-6._11-5 > * {\n      height: 20px;\n      width: 20px;\n    }\n\n._11-6._11-7 > * {\n      width: unset;\n    }\n\n/* Needed to make the large icon only button a perfect square */\n\n._11-6._11-4 {\n    padding-right: 2px;\n    padding-left: 2px;\n  }\n\n._11-8 > :nth-child(1) > button {\n    border-top-right-radius: 0;\n    border-bottom-right-radius: 0;\n  }\n\n._11-8 > :nth-child(2) > button {\n    border-left: 0px;\n    border-top-left-radius: 0;\n    border-bottom-left-radius: 0;\n    margin-left: -1px;\n  }\n\n._11-9 {\n  width: 100%;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  align-self: baseline;\n}\n\n._11-a {\n  width: 100%;\n}\n\n._11-a._11-b {\n    opacity: 0;\n  }\n", ""]);
// Exports
export var button = "_11-0";
export var xs = "_11-1";
export var sm = "_11-2";
export var md = "_11-3";
export var lg = "_11-4";
export var xl = "_11-5";
export var iconContainer = "_11-6";
export var dontRestrainIconWidth = "_11-7";
export var dropdownButton = "_11-8";
export var spinner = "_11-9";
export var buttonContent = "_11-a";
export var pending = "_11-b";
export default ___CSS_LOADER_EXPORT___;
