// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../k8-opt-exec-ST-13d3ddad9198/bin/redo/shopify-extension/webpack.bin.runfiles/npm_css-loader_6.7.3-dc3fc578/files/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../k8-opt-exec-ST-13d3ddad9198/bin/redo/shopify-extension/webpack.bin.runfiles/npm_css-loader_6.7.3-dc3fc578/files/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._z-0 {\n  display: flex;\n  gap: 10px;\n}\n\n._z-0._z-1 {\n  gap: 4px;\n}\n\n._z-0._z-2 {\n  gap: 4px;\n}\n\n._z-0._z-3 {\n  gap: 6px;\n}\n\n._z-0._z-4 {\n  flex-direction: column;\n  color: var(--redo-colors-text-text-primary-900);\n}\n\n._z-5 {\n  /* align-self: flex-start; */\n  color: var(--redo-colors-text-text-secondary-700);\n}\n\n._z-5._z-1 {\n    font-size: var(--redo-body-xsmall-text-size);\n  }\n\n._z-5._z-2 {\n    font-size: var(--redo-body-small-text-size);\n  }\n\n._z-0._z-6 ._z-5 {\n  font-size: var(--redo-body-medium-text-size);\n  font-weight: normal;\n}\n\n._z-0._z-7 ._z-5 {\n  font-weight: 500;\n}\n\n._z-0._z-8 ._z-5 {\n  font-size: var(--redo-body-small-text-size);\n  font-weight: normal;\n}\n\n._z-9 {\n  color: var(--redo-colors-text-text-error-primary-600);\n}\n\n/* Had to add this because the LabelTheme prop is messing with\nfont sizes for some reason */\n._z-0._z-7 ._z-5._z-1 {\n  font-size: var(--redo-body-xsmall-text-size);\n}\n", ""]);
// Exports
export var container = "_z-0";
export var xs = "_z-1";
export var sm = "_z-2";
export var md = "_z-3";
export var vertical = "_z-4";
export var label = "_z-5";
export var light = "_z-6";
export var thinBold = "_z-7";
export var thin = "_z-8";
export var error = "_z-9";
export default ___CSS_LOADER_EXPORT___;
